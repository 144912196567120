import React, {FC} from "react";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import Link from "next/link";
import {BlogSVG, ContactusSVG, TrackingSVG} from "../../svg";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
import {changeLocale} from "../../store/locale";
import {mobileMenuClose} from "../../store/mobile-menu";
import {IItem} from "../../types/header";
import cls from "./mobile.module.scss"

const MobileLinks = dynamic(() => import('./MobileLinks'))
const MobileHeader = dynamic(() => import('./MobileHeader'))
const MobileMenuFooter = dynamic(() => import('../footer/FooterMobile/MobileMenuFooter'))
const MobileLinksForPages = dynamic(() => import('./MobileLinksForPages'))

interface IProps {
    pageProps: any
}

const MobileMenu: FC<IProps> = ({pageProps}) => {
    const dispatch = useDispatch();


    const hasBlog = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.theme_blog_active?.value)))
    const hasTrackingActive = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.sales_tracking_tracking_active?.value)))
    const hasContactWidthUs = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.theme_contact_us_active?.value)));

    const mobileMenuState = useSelector((state: IState) => state.mobileMenu);
    const menuPagesList = useSelector((state: IState) => state.mobileMenu.mobileMenuList);

    const pages = <FormattedMessage id="pages" defaultMessage="Pages"/>;
    const category = (
        <FormattedMessage id="categoies" defaultMessage="Category"/>
    );

    const classes = classNames(cls["mobilemenu"],
        mobileMenuState.open && cls["mobilemenu--open"]
    );

    const navLinksMobile = useSelector((state: IState) =>
        state.general.menuList?.filter((items: {
            type: string;
        }) => items.type == "header").sort((a, b) => Number(a.position) - Number(b.position))
    );
    const navLinksMobileFooter = useSelector((state: IState) =>
        state.general.menuList?.filter((items) => items.type == "footer").sort((a, b) => Number(a.position) - Number(b.position))
    );

    const together = [...navLinksMobile, ...navLinksMobileFooter]
    const handleItemClick = (item?: { data?: { type: string; locale: string; }; type?: string; }) => {

        if (item?.data) {
            if (item.data.type === "language") {
                dispatch(changeLocale(item.data.locale));
                dispatch(mobileMenuClose());
            }
        }
        dispatch(mobileMenuClose());
    };

    const statecategories = useSelector((state: IState) => state.general.categories);
    const categories = [{label: category, children: statecategories}];
    return (
        <div className={classes}>
            <div className={cls.mobilemenu__backdrop} onClick={() => dispatch(mobileMenuClose())}/>
            <div className={cls.mobilemenu__body}>
                {/*<MobileHeader/>*/}
                <div className={cls.mobilemenu__content}>
                    {/*<MobileLinks links={categories as IItem[]} onItemClick={handleItemClick}/>*/}
                    {menuPagesList ? (
                        <>
                            <MobileLinksForPages
                                links={[
                                    {
                                        label: pages,
                                        childs: [...together]
                                    }
                                ]}
                                onItemClick={handleItemClick}
                            />

                            {
                                hasBlog ? (
                                        <ul className={cls["mob-links__item"]}>
                                            <Link href="/blog">
                                                <a onClick={() => dispatch(mobileMenuClose())}>
                                                    <span className={cls["mobile-links__blok"]}>
                                                        <BlogSVG/>
                                                        <FormattedMessage id="blog" defaultMessage="Blog"/>
                                                    </span>
                                                </a>
                                            </Link>
                                        </ul>
                                    ) :
                                    <></>
                            }
                            {hasContactWidthUs && (
                                <div
                                    className={cls["mob-links__item"]}
                                >
                                    <Link href="/contact">
                                        <a onClick={() => dispatch(mobileMenuClose())}>
                                            <span className={cls["mobile-links__blok"]}>
                                                <ContactusSVG/>
                                                <FormattedMessage
                                                    id="contact_with_us"
                                                    defaultMessage="Contact With Us"
                                                />
                                            </span>
                                        </a>

                                    </Link>
                                </div>
                            )}
                            {hasTrackingActive && (
                                <div
                                    className={cls["mob-links__item"]}
                                    style={{borderBottom: "none"}}
                                >
                                    <Link href="/tracking">
                                        <a onClick={() => dispatch(mobileMenuClose())}>
                                            <span className={cls["mobile-links__blok"]}>
                                                <TrackingSVG/>
                                                <FormattedMessage
                                                    id="tracking"
                                                    defaultMessage="Tracking Number"
                                                />
                                            </span>
                                        </a>
                                    </Link>
                                </div>
                            )}

                        </>
                    ) : (
                        ""
                    )}
                </div>
                <MobileMenuFooter pageProps={pageProps}/>
            </div>
        </div>
    );
}


export default MobileMenu
