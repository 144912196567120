import React, {FC} from "react";
import {useSelector} from "react-redux";
import dynamic from 'next/dynamic'
import {IState} from "../../../types/state";
import {detectLocale} from "../../../services/utils";
import footerCls from "../Footer-scss/Footer.module.scss"
import {useRouter} from "next/router";
import {IProps} from "../../../pages/signin";
const FooterNewsletter = dynamic(() => import("../FooterNewsletter"))
const MobileFooterNavs = dynamic(() => import("../../mobile/MobileFooterNavs"))

const MobileFooter: FC<{pageProps: any}> = ({pageProps}) => {
    const router = useRouter();
    const siteLocale = useSelector((state: IState) => state.locale.code)
    const showSubscription = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.theme_subscription_active?.value)))
    const footerToggle = Boolean(+detectLocale(useSelector((state: IState) => state.general.coreConfigs.general_content_footer_footer_toggle), siteLocale)?.value)
    const footerContent = detectLocale(useSelector((state: IState) => state.general.coreConfigs.general_content_footer_footer_content), siteLocale)?.value
    const {footerContent: pagePropsFooterContent} = pageProps

    return (
        <div>
            <div className={footerCls["mobile-footer"]}>
                {/*TODO:change container and home product container to container from globals.module.scss when globals file will ready*/}
                {showSubscription &&
                    <div className="container home-product-container">
                        <div className={footerCls["mobile-footer-container"]}>

                            <div className={footerCls["mobile-footer-newsletter"]}>
                                <FooterNewsletter />
                                {/*{footerToggle && <div className={footerCls.copyright}>{footerContent}</div>}*/}
                                {footerToggle &&
                                    <div style={{height: 23}}>
                                        <div className={footerCls.copyright}>
                                            {router.asPath === "/" ? pagePropsFooterContent?.value : footerContent}
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                }
            </div>

            {/*<div>*/}
            {/*    <MobileFooterNavs/>*/}
            {/*</div>*/}

        </div>
    );
}

export default MobileFooter;
