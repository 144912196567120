import React, {FC, ReactNode, useEffect, useState} from "react";
import jwt_decode, {JwtPayload} from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";
import {AddCartToken} from "../store/token";
import dynamic from 'next/dynamic'
import {generateScript} from "../scripts/generateScript";
import {IState} from "../types/state";
import Suspend from "../pages/suspend";
import ToastProvider from "../atoms/Toast/ToastProvider";

// const PopupModal = dynamic(() => import("./popupComponents/PopupModal"))
// const MainContent = dynamic(() => import("./MainContent"))
// const AcceptCookies = dynamic(() => import("./AcceptCookies"))

import PopupModal from "./popupComponents/PopupModal"
import MainContent from "./MainContent"
import AcceptCookies from "./AcceptCookies"
import useSuspend from "../hooks/useSuspend";

interface IProps {
    children: ReactNode
    locale: string
}

const Layout: FC<IProps> = ({children, locale}) => {
    const customJs = useSelector((state: IState) => state.general.customJs);
    const dbName = useSelector((state: IState) => state.general.dbName);
    const popUp = useSelector((state: IState) => state.general.popUp);
    const cartToken = useSelector((state: IState) => state.cartToken);
    const upCrossProd = useSelector((state: IState) => state.general.upCrossProd);
    const customer = useSelector((state: IState) => state.customer);
    const searchOpen = useSelector((state: IState) => state.mobileMenu.searchOpen);
    const dispatch = useDispatch();
    const {suspend} = useSuspend();

    useEffect(() => {
        if (customJs) {
            generateScript(customJs)
        }
    }, [customJs]);


    useEffect(() => {
        const abortController = new AbortController();

        if (customer.token) {
            const decodedToken: JwtPayload = jwt_decode(customer.token);
            if (Date.now() >= (decodedToken as { exp: number }).exp * 1000) {
                dispatch({type: "AUTHENTICATED", payload: false});
                dispatch({type: "CUSTOMER_TOKEN", payload: ""});
            }
        }
        return function cleanUp() {
            abortController.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer.token]);

    //fix this bug after checking issue
    useEffect(() => {
        if (!cartToken?.cartToken) {
            fetch(`/api/checkout/cart/token`)
                .then((response) => response.json())
                .then(res => {
                    if (res.api_token) {
                        dispatch(AddCartToken(res.api_token));
                    }
                })
                .catch((err) => console.error(err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {
                suspend ?
                    <Suspend DomainName={`${dbName}`}/>
                    :
                    <>
                        {/*<ToastProvider/>*/}
                        <div className="site">
                            <MainContent>
                                <div className={`site__body`}>
                                    {searchOpen && <div className={"background_layer"}></div>}
                                    {children}</div>
                            </MainContent>
                            {popUp && <PopupModal active={popUp} upCrossProd={upCrossProd} locale={locale}/>}
                            {/*@ts-ignore*/}
                            <AcceptCookies/>
                        </div>
                    </>

            }
        </>
    );
}

export default Layout;
