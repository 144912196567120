import scriptsfm from "script-tags";

export const generateScript = (customJs) => {
    const jsCode = scriptsfm(customJs);
    if (jsCode) {
        jsCode.forEach((element) => {
            const {attrs, text} = element;
            const scriptTag = document.createElement("script");
            const attrKeys = Object.keys(attrs);

            for (let i = 0; i < attrKeys.length; i++) {
                scriptTag.setAttribute(attrKeys[i], attrs[attrKeys[i]]);
            }
            if (text) {
                scriptTag.innerText = text;
            }
            document.body.appendChild(scriptTag);
        });
    }
}