import React, {FC, useState} from "react";
import Link from "next/link";
import {useDispatch, useSelector} from "react-redux";
import {domainUrl} from "../../helper";
import Head from "next/head";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
import cls from "./header.module.scss"
import {useRouter} from "next/router";
import {PropsConsumer} from "../../context";
import Image from "next/image";

const NavLinks = dynamic(() => import("./NavLinks/NavLinks"))

// const NavPanel = dynamic(() => import("./NavPanel/NavPanel"))

interface IHeaderProps {
    layout?: string
    pageProps: any
}

const Header: FC<IHeaderProps> = ({layout = "default", pageProps}) => {
    const dispatch = useDispatch();
    // const dbName = useSelector((state: IState) => state.general.dbName)
    const dbName = pageProps?.dbName
    const domain = pageProps?.domain
    const cacheVersion = useSelector((state: IState) => state.general.cashVersion)
    const router = useRouter();

    const [imgSrc, setImgSrc] = useState(`${dbName}/storage/${domain}/configuration/logo/logo.webp?v=${cacheVersion}`)

    const loadDefaultLogo = () => {
        setImgSrc(`${dbName}/stores-logo.svg?v=${cacheVersion}`)
    }
    /*FIXME make isolated file for all schema files*/
    const schemaSiteHeader = {
        "@context": `https://schema.org/`,
        "@type": "ItemList",
        "name": "Site Header Items",
        "description": "contact, change currency and language",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                name: "Telephone"
            },
            {
                "@type": "ListItem",
                "position": 2,
                name: "Currency"
            },
            {
                "@type": "ListItem",
                position: 3,
                name: "Language"
            },
        ],
        url: domainUrl(dbName + router.asPath)
    }

    return (
        <>
            <Head>
                <script type="application/ld+json"
                        dangerouslySetInnerHTML={{__html: JSON.stringify(schemaSiteHeader)}}
                />
            </Head>
            <div className={cls["site-header"]}>
                <div className={`${cls["site-header__middle"]} container`}>
                    <div className={cls.header__logo}>
                        <Link href="/">
                            <a>
                                <Image
                                    src={domainUrl(imgSrc)}
                                    alt="logo"
                                    placeholder="blur"
                                    layout="fill"
                                    blurDataURL={`/zega/blur1.webp`}
                                    objectFit='contain'
                                    objectPosition="left"
                                    onError={loadDefaultLogo}
                                />
                            </a>
                        </Link>
                    </div>
                    <div className={cls["site-header__search"]}>
                        <PropsConsumer>
                            {
                                props => (
                                    <NavLinks pageProps={props}/>
                                )
                            }
                        </PropsConsumer>
                    </div>
                </div>
                {/*<div className={cls["site-header__nav-panel"]}>*/}
                {/*    <NavPanel layout={layout}/>*/}
                {/*</div>*/}
            </div>
        </>
    );
}

export default React.memo(Header);
