import React, {FC, memo, useState} from "react";
import Link from "next/link";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {domainUrl} from "../../helper";
import {Cross20Svg, Mobilemenu,} from "../../svg";
import {mobileMenuOpen, mobileSearchClose} from "../../store/mobile-menu";
import dynamic from 'next/dynamic'
import cls from "./mobile.module.scss"
import {IState} from "../../types/state";
import Image from "next/image";

const Search = dynamic(() => import('../header/Search/Search'))
const DropdownLanguage = dynamic(() => import('../header/Dropdown/DropdownLanguage'))

interface IProps {
    pageProps: any
}

const MobileHeader: FC<IProps> = ({pageProps}) => {
    const {dbName, domain} = pageProps
    // const domain = useSelector((state: IState) => state.general.domain)
    // const dbName = useSelector((state: IState) => state.general.dbName)

    const mobileMenu = useSelector((state: IState) => state.mobileMenu.open)
    const cacheVersion = useSelector((state: IState) => state.general.cashVersion)
    const searchReduxState = useSelector((state: IState) => state.mobileMenu.searchOpen)

    const dispatch = useDispatch()

    const [searchOpen, setSearchOpen] = useState<boolean>(false);

    const handleOpenSearch = () => setSearchOpen(true)
    const handleCloseSearch = () => setSearchOpen(false)

    const [imgSrc, setImgSrc] = useState(`${dbName}/storage/${domain}/configuration/logo/logo.webp?v=${cacheVersion}`)
    const loadDefaultLogo = () => {
        setImgSrc(`${dbName}/stores-logo.svg?v=${cacheVersion}`)
    }


    const searchClasses = classNames(cls["mobile-header__search"], searchReduxState ? cls["mobile-header__search--open"] : "");

    const ClosedOrOpen = !mobileMenu ? <Mobilemenu/> : <Cross20Svg/>

    return (
        <div className={cls["mobile-header"]}>
            <div className={cls["mobile-header__panel"]}>
                <div>
                    <div className={`${cls["mobile-header__body"]} container`}>
                        <button
                            type="button"
                            className={cls["mobile-header__menu-button"]}
                            onClick={() => dispatch(mobileMenuOpen())}
                            aria-label="MobileHeaderBtn"
                        >
                            {ClosedOrOpen}
                        </button>
                        <div className={cls.header__logo}>
                            <Link href="/">
                                <a aria-label="go to home page">
                                    <Image
                                        src={domainUrl(imgSrc)}
                                        alt="mobile-logo"
                                        placeholder="blur"
                                        layout="fill"
                                        blurDataURL={`/zega/blur1.webp`}
                                        objectFit='contain'
                                        onError={loadDefaultLogo}
                                    />
                                </a>
                            </Link>
                        </div>

                        {/*<div className={cls["mobile-header__indicators"]}>*/}
                        {/*    <Indicator*/}
                        {/*        className={`${cls["indicator--mobile"]} ${cls["search-icon"]}`}*/}
                        {/*        onClick={handleOpenSearch}*/}
                        {/*        icon={<SearchssSvg/>}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className="mobile-header-dropdown">
                            <DropdownLanguage />
                        </div>
                    </div>
                        <Search
                            context="mobile-header"
                            className={searchClasses}
                            onClose={() => dispatch(mobileSearchClose())}
                        />

                </div>
            </div>
        </div>
    );
}

export default memo(MobileHeader);
