import {useEffect, useState} from "react";
import shopApi from "../api/shop";

const useSuspend = () => {
    const [suspend, setSuspend] = useState<boolean>(false)
    useEffect(() => {
        /*** REMEMBER !!! this request is only for checking suspend state, do not remove this request!!! ***/
        shopApi.checkSuspend()
            .then((suspend) => {
                if (suspend.status === 469) {
                    setSuspend(true);
                }
            })
    }, [])

    return {suspend}
}

export default useSuspend