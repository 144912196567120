import React, {FC, memo, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
import {detectLocale} from "../../services/utils";
import SSRAccount from "./FooterAccount/index";
import {useRouter} from "next/router";
import footerCls from "./Footer-scss/Footer.module.scss"
import cls from "../header/header.module.scss"
import Link from "next/link";
import Image from "next/image";
import {domainUrl} from "../../helper";
import {FormattedMessage} from "react-intl";
import {ArrowRoundedDown9x6Svg} from "../../svg";

const FooterContacts = dynamic(() => import('./FooterContacts'))
const FooterNewsletter = dynamic(() => import('./FooterNewsletter'))
const ToTop = dynamic(() => import('./ToTop'))

const FooterAccount = dynamic(() => import('./FooterAccount'))

interface IProps {
    pageProps: any
}

const Footer: FC<IProps> = ({pageProps}) => {
    const router = useRouter();
    const siteLocale = useSelector((state: IState) => state.locale.code)
    let realPath = router.locale !== 'catchAll' ? router.locale + '/' : '';
    const dbName = pageProps?.dbName
    const domain = pageProps?.domain
    const cacheVersion = useSelector((state: IState) => state.general.cashVersion)
    const footerContent = detectLocale(useSelector((state: IState) => state.general.coreConfigs.general_content_footer_footer_content), siteLocale)?.value
    const footerToggle = detectLocale(useSelector((state: IState) => state.general.coreConfigs.general_content_footer_footer_toggle), siteLocale)?.value
    const menuList = useSelector((state: IState) => state.general.menuList);
    const [imgSrc, setImgSrc] = useState(`${dbName}/storage/${domain}/configuration/logo/logo.webp?v=${cacheVersion}`)
    const navLinks = (router.asPath === "/" ? pageProps?.menus : menuList)?.filter((el: any) => el.type === "footer")
    const {footerContent: pagePropsFooterContent} = pageProps

    const loadDefaultLogo = () => {
        setImgSrc(`${dbName}/stores-logo.svg?v=${cacheVersion}`)
    }


    return (
        <div className={footerCls["site-footer"]}>
            {/*TODO:change container to container from globals.module.scss when globals file will ready*/}
            <div className={`${footerCls["footer-container"]} container`}>
                <div className={footerCls["footer-body"]}>
                    <div className={footerCls["footer-first-column"]}>
                        <div>
                            <div className={cls.header__logo}>
                                <Link href="/">
                                    <a>
                                        <Image
                                            src={domainUrl(imgSrc)}
                                            alt="logo"
                                            placeholder="blur"
                                            layout="fill"
                                            blurDataURL={`/zega/blur1.webp`}
                                            objectFit='contain'
                                            objectPosition="left"
                                            onError={loadDefaultLogo}
                                        />
                                    </a>
                                </Link>
                            </div>
                            <div className={footerCls.footer_text}>
                                <p>
                                    <FormattedMessage
                                        id="footer_text"
                                        defaultMessage="Lorem ipsum dolor sit amet consectetur. Proin nunc proin et sed. "
                                    />
                                </p>

                            </div>
                        </div>

                        {router.asPath === "/" ? (
                            <FooterAccount
                                pageProps={pageProps}
                            />
                        ) : (
                            <SSRAccount/>
                        )}
                    </div>

                    {navLinks?.length > 0 && <div className={footerCls["footer-newsletter-hide"]}>
                        <h3 className={footerCls["footer-newsletter__title"]}>
                            <FormattedMessage
                                id="pages"
                                defaultMessage="Pages"
                            />
                        </h3>
                        <ul className={footerCls["footer-contacts__contacts"]}>

                            {navLinks.map((menu: any, index: number) => {
                                return <Link
                                    key={index}
                                    href={(menu.url_key || menu.slug && `/${realPath}page/${menu.slug}`) || ""}
                                    prefetch={false}
                                >
                                    <div className={footerCls.contactsAddress}>
                                    {
                                        menu.new_tab
                                            ? <a target="_blank">
                                                {menu.name}
                                            </a>
                                            : <a>
                                                {menu.name}
                                            </a>
                                    }
                                    </div>
                                </Link>

                            })}

                        </ul>
                    </div>
                    }

                    <div className={footerCls["footer-newsletter-hide"]}>
                        <h3 className={footerCls["footer-newsletter__title"]}>
                            <FormattedMessage
                                id="footer_contacts"
                                defaultMessage="Contact"
                            />
                        </h3>
                        <FooterContacts pageProps={pageProps}/>
                    </div>


                    <div className={footerCls["newsletter-block"]} style={{display: pageProps.showSubscription}}>
                        <FooterNewsletter/>
                    </div>
                </div>
                {footerToggle === "1" &&
                    <div style={{height: 23}}>
                        <div className={footerCls.copyright}>
                            {router.asPath === "/" ? pagePropsFooterContent?.value : footerContent}
                        </div>
                    </div>}
            </div>
            <ToTop/>
        </div>
    );
}

export default memo(Footer);
