import React from "react";
import Link from "next/link";
import {useSelector} from "react-redux";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
import {IProps} from "../../types/homePageTypes";
import cls from "./header.module.scss"

const DropdownLanguage = dynamic(() => import("./Dropdown/DropdownLanguage"))

function TopBar() {
    const phone = useSelector((state: IState) => state.general.coreConfigs.general_info_general_phone?.value);
    const linksList = (
        <div className={cls.topBar__item}>
            {
                phone
                    ? (
                        <Link href={`tel:${phone}`}>
                            <a>
                                {phone}
                            </a>
                        </Link>
                    )
                    : (
                        ""
                    )
            }
        </div>
    );

    return (
        <div className={cls.topBar}>
            <div className={`${cls.topBar__container} container`}>
                <div className={cls.topBar__row}>
                    {linksList}
                    <div className="language-adjust">
                        <DropdownLanguage/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
