import React, {FC, JSXElementConstructor, ReactElement, ReactNode, useEffect, useRef, useState} from "react";
import {PropsConsumer} from "../context";
import {useRouter} from "next/router";
import {setWindowSize} from "../store/general";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../types/state";

import TopBar from "./header/TopBar"
import Footer from "./footer"
import Header from "./header"
import MobileMenu from "./mobile/MobileMenu"
import MobileFooter from "./footer/FooterMobile/MobileFooter"
import MobileHeader from "./mobile/MobileHeader"
import {mobileMenuClose} from "../store/mobile-menu";

interface IFooters {
    isTablet: boolean
    pageProps: any
}

interface IHeaders {
    isTablet: boolean
    pageProps: any
    headerRef: any
}

const Footers: FC<IFooters> = ({isTablet, pageProps}) => {
    return (
        isTablet
            ? <MobileFooter pageProps={pageProps}/>
            : <Footer pageProps={pageProps}/>
    )
}

const Headers: FC<IHeaders> = ({isTablet, headerRef, pageProps}) => {
    return (
        isTablet
            ? <div className="m_headerWrapper" ref={headerRef}>
                <MobileHeader pageProps={pageProps}/>
                <MobileMenu pageProps={pageProps}/>
            </div>
            : <>
                <TopBar/>
                <div className="d_headerWrapper">
                    <Header pageProps={pageProps}/>
                </div>
            </>
    )
}

const MainContent = ({children}: { children: ReactNode }) => {
    const dispatch = useDispatch()
    const router = useRouter()
    let windowSize = useSelector((state: IState) => state.general.windowSize)
    const [isTablet, setIsTablet] = useState(false)
    const headerRef = useRef<any>(null)
    const footerRef = useRef<any>(null)
    let scrollTimeout: any

    useEffect(() => {
        const {query} = router

        if (query.room_id) {
            sessionStorage.setItem("roomID", query.room_id as string)
        }
    }, [])

    useEffect(() => {

        const windowResizeEvent = () => {
            dispatch(setWindowSize(window.innerWidth))
        }

        window.addEventListener("resize", windowResizeEvent)

        if (!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsTablet(windowSize <= 991)

        return () => {
            window.removeEventListener("resize", windowResizeEvent)
        }
    }, [windowSize]);

    useEffect(() => {
        let prevPos = window.pageYOffset;

        const scrollFunc = () => {
            if (headerRef.current) {
                if (window.pageYOffset > prevPos) {
                    headerRef.current.style.transform = 'translate(0, -60px)';
                    dispatch(mobileMenuClose())
                } else {
                    headerRef.current.style.transform = 'unset';
                }
                prevPos = window.pageYOffset;
            }

            clearTimeout(scrollTimeout);

            scrollTimeout = setTimeout(() => {
                if (headerRef.current) {
                    headerRef.current.style.transform = 'unset';
                }
            }, 300);
        };

        window.addEventListener("scroll", scrollFunc)

        return () => {
            window.removeEventListener('scroll', scrollFunc);
            clearTimeout(scrollTimeout);
        };
    }, []);

    return (
        <PropsConsumer>
            {
                props => {
                    return (
                        <>
                            <Headers
                                isTablet={isTablet}
                                headerRef={headerRef}
                                pageProps={props}
                            />
                            {children}
                            <Footers
                                isTablet={isTablet}
                                pageProps={props}
                            />
                        </>
                    )
                }
            }
        </PropsConsumer>
    )
}

export default MainContent;