import React from "react";
import CookieConsent from "react-cookie-consent";
import {CookieSVG} from "../svg";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {detectLocale} from "../services/utils";
import {IState} from "../types/state";

export default function AcceptCookies() {
    const siteLocale = useSelector((state: IState) => state.locale.code)
    const gdprActive = Boolean(+detectLocale(useSelector((state: IState) => state.general.coreConfigs.general_gdpr_general_active), siteLocale)?.value)
    const gdprTitle = detectLocale(useSelector((state: IState) => state.general.coreConfigs.general_gdpr_general_title), siteLocale)?.value
    const gdprContent = detectLocale(useSelector((state: IState) => state.general.coreConfigs.general_gdpr_general_content), siteLocale)?.value

    return (
        gdprActive && <CookieConsent
            location="bottom"
            buttonText={
                <FormattedMessage
                    id="gdpr_button"
                    defaultMessage="Accept all cookies"
                />
            }
            visible="byCookieValue"
            buttonClasses="cookie_button"
            buttonWrapperClasses="cookie_button_wrapper"
            expires={30}
            containerClasses="cookie_body"
            contentClasses="cookie_content"
        >
            <div className="cookie_body_elements">
                <div className="cookie_image">
                    <CookieSVG/>
                </div>

                <div>
                    <h3>
                        {gdprTitle}
                    </h3>
                    <p>
                        {gdprContent}
                    </p>
                </div>
            </div>
        </CookieConsent>
    );
}
