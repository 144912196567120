import React, {FC, memo, useMemo, useState} from 'react';
import {ToastContext} from './ToastContext';
import {Toast} from './Toast';
import cls from "../atoms.module.scss"

// Create a random ID
function generateUEID() {
    let first: string | number = (Math.random() * 46656) | 0;
    let second: string | number = (Math.random() * 46656) | 0;
    first = ('000' + first.toString(36)).slice(-3);
    second = ('000' + second.toString(36)).slice(-3);

    return first + second;
}

const ToastProvider: FC<any> = ({children}) => {
    const [toasts, setToasts] = useState<any[]>([]);
    const open = (content: any) =>
        setToasts((currentToasts) => [
            ...currentToasts,
            {id: generateUEID(), content},
        ]);
    const close = (id: any) =>
        setToasts((currentToasts) =>
            currentToasts.filter((toast) => toast.id !== id)
        );

    const contextValue = useMemo(() => ({open}), []);

    const renderedToasts = toasts?.map(({id, content}) => (
        <Toast key={id} close={() => close(id)}>
            {content}
        </Toast>
    ));

    return (
        <ToastContext.Provider value={contextValue}>
            {children}
            <div className={cls["toasts-wrapper"]}>{renderedToasts}</div>
        </ToastContext.Provider>
    );
};

export default memo(ToastProvider)
