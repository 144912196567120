import React, {useEffect} from 'react'
import {setPopup} from '../../store/general'
import {useDispatch, useSelector} from 'react-redux'
import dynamic from 'next/dynamic'
import cls from "./popup.module.scss"

const UpSell = dynamic(() => import('./UpSell'))
const CrossSell = dynamic(() => import('./CrossSell'))
const CrosselSecond = dynamic(() => import('./CrosselSecond'))
const PasswordChangePoup = dynamic(() => import('../account/PasswordChangePoup'))
const DetailsImagePopup = dynamic(() => import('../shared/products/DetailsImagePopup'))
const IndicatoDropDownBody = dynamic(() => import('../header/Indicator/IndicatoDropDownBody'))
const ZoomIn = dynamic(() => import('../shared/ZoomIn/ZoomIn'))

function PopupModal({active, upCrossProd, locale}) {

    const popUpName = useSelector(state => state.general.popUpName)
    const hastitle = useSelector(state => state.general.crossValid)
    const detailUrl = useSelector(state => state.general.detailUrl)
    const imgUrl = useSelector(state => state.general.imgUrl)
    const dispatch = useDispatch()
    useEffect(() => {

    }, [upCrossProd])
    if (!popUpName) {
        return null
    }

    let content
    if (popUpName === 'register') {
        content = <IndicatoDropDownBody/>
    } else if (popUpName === 'passwordChange') {
        content = <PasswordChangePoup/>
    } else if (popUpName === 'upsell') {
        content = <UpSell product={upCrossProd[0]} locale={locale}/>
    } else if (popUpName === 'crossSell') {
        content = <CrossSell product={upCrossProd} hasTitle={!hastitle}/>
    } else if (popUpName === 'crossel2') {
        content = <CrosselSecond product={upCrossProd}/>
    } else if (popUpName === 'details_image') {
        content = <DetailsImagePopup detailUrl={detailUrl}/>
    } else if(popUpName === "zoom_in") {
        content = <ZoomIn url={imgUrl}/>
    }

    return (
        <>
            <div
                className={`${cls.modalss} ${active && cls.active}`}
                onClick={() => dispatch(setPopup(false))}
            >
                <div
                    className={
                        active ? `${cls["modal-content"]} ${cls.active} ${cls[popUpName]}` : cls["modal-content"]
                    }
                    onClick={(e) => e.stopPropagation()}
                >
                    {content}
                </div>
            </div>

        </>
    )
}


export default PopupModal
