import React, {FC, useEffect, useState} from "react";
import Link from "next/link";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {IMenuList, IState} from "../../../types/state";
import {IFooterAccount as IProps} from ".././FooterNewsLetter/types";
import footerCls from "../Footer-scss/Footer.module.scss"
const SSRAccount: FC = () => {
    // TODO!!!! without SSR
    const [footerLinks, setFooterLinks] = useState<Array<IMenuList>>([]);
    const hasTracking = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.sales_tracking_tracking_active?.value)))
    const hasContact = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.theme_contact_us_active?.value)));
    const menuList = useSelector((state: IState) => state.general.menuList)
    const locale = useSelector((state: IState) => state.locale)

    useEffect(() => {
        setFooterLinks(menuList?.filter((item) => item.type === "footer"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale])

    return (
        <>
            {hasContact && (<div className={footerCls["trackingNumber-fms"]}>
                <Link href="/contact">
                    <a>
                        <FormattedMessage
                            id="contact_with_us"
                            defaultMessage="Contact With Us"
                        />
                    </a>
                </Link>
            </div>)}
            {hasTracking && (<div className={footerCls["trackingNumber-fms"]}>
                <Link href="/tracking">
                    <a>
                        <FormattedMessage
                            id="tracking"
                            defaultMessage="Tracking Number"
                        />
                    </a>
                </Link>
            </div>)}
            {/*{footerLinks.length > 0 &&*/}
            {/*    footerLinks*/}
            {/*        .sort((a: IMenuList, b: IMenuList) => a.position - b.position)*/}
            {/*        .map((link, index) => (*/}
            {/*                <div key={index} className={footerCls["footerLinks-fms"]} >*/}
            {/*                    <Link*/}
            {/*                        key={link.id}*/}
            {/*                        href={link.url_key ? link.url_key : `/page/${link.slug}`}*/}
            {/*                        target={link.url_key && "_blank"}*/}
            {/*                    >*/}
            {/*                        <a>{link.name}</a>*/}
            {/*                    </Link>*/}
            {/*                </div>*/}
            {/*            )*/}
            {/*        )*/}
            {/*}*/}
        </>
    );
}

export default SSRAccount;